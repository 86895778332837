import { Link, navigate } from '@reach/router';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';

export default ({ location }) => {
	const { propertyId } = location.state;
	const [bookingDate, setBookingDate] = useState(new Date());
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [agentCode, setAgentCode] = useState('');
	const [totalQuantity, setTotalQuantity] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const [bookingDetails, setBookingDetails] = useState({});
	const [ticketPrices, setTicketPrices] = useState([]);
	console.log(ticketPrices)
	const current = new Date();
	let currentDate = current.getDate() > 9 ? current.getDate() : `0${current.getDate()}`;
	let currentMonth = current.getMonth() > 9 ? current.getMonth() + 1 : `0${current.getMonth() + 1}`;
	let currentYear = current.getFullYear() > 9 ? current.getFullYear() : `0${current.getFullYear()}`;

	const minDateValue = `${currentYear}-${currentMonth}-${currentDate}`;

	console.log("bookingDetails", Object.values(bookingDetails))
	useEffect(() => {
		if (propertyId) {
			axios
				.get(
					process.env.REACT_APP_API_URL +
					'/get-ticket-prices/' +
					propertyId,
				)
				.then(response => {
					setTicketPrices(response.data);
					const script = document.createElement('script');
					script.src = 'https://checkout.razorpay.com/v1/checkout.js';
					script.async = true;
					document.body.appendChild(script);
				})
				.catch(error => {
					console.log(error);
					// navigate('/');
				});
		} else {
			navigate('/');
		}
	}, [propertyId]);

	useEffect(() => {
		let bookingDetails = {};

		ticketPrices.forEach(
			ticketPrice =>
			(bookingDetails = {
				...bookingDetails,
				[ticketPrice.id]: {
					ticketPriceId: ticketPrice.id,
					quantity: 0,
					price: ticketPrice.price,
				},
			}),
		);

		setBookingDetails(bookingDetails);
		setTotalQuantity(0);
		setTotalPrice(0);
	}, [ticketPrices]);

	const manageTicketCount = (action, ticketPriceId) => {
		const quantity = bookingDetails[ticketPriceId].quantity;
		const price = bookingDetails[ticketPriceId].price;

		if (action === 'increment' && quantity < 30) {
			setBookingDetails({
				...bookingDetails,
				[ticketPriceId]: {
					...bookingDetails[ticketPriceId],
					quantity: quantity + 1,
				},
			});

			setTotalQuantity(totalQuantity + 1);
			setTotalPrice(totalPrice + price);
		} else if (action === 'decrement' && quantity > 0) {
			setBookingDetails({
				...bookingDetails,
				[ticketPriceId]: {
					...bookingDetails[ticketPriceId],
					quantity: quantity - 1,
				},
			});

			setTotalQuantity(totalQuantity - 1);
			setTotalPrice(totalPrice - price);
		}
	};

	const handleSubmit = event => {
		event.preventDefault();
		if (bookingDate === '') {
			alert('Please enter booking date');
		} else if (email === '') {
			alert('Please enter email');
		} else if (phone === '') {
			alert('Please enter mobile number');
		} else if (totalQuantity === '') {
			alert('Ticket quantity must be greater than zero');
		} else {
			axios
				.post(
					process.env.REACT_APP_API_URL + '/book-ticket'
					, {
						propertyId,
						bookingDate,
						email,
						phone,
						agentCode,
						bookingDetails: Object.values(bookingDetails),
						couponCode: '',
						paymentMethod: 'ONLINE',
					})
				.then(response => {
					const { orderId, razorPayOrderId } = response.data;
					console.log(razorPayOrderId);
					const options = {

						key: process.env.REACT_APP_RAZORPAY_KEY, // Replace with your actual key ID

						amount: totalPrice * 100, // Replace with the actual amount in paise
						currency: 'INR', // Replace with your currency code
						name: 'jaisalmer trust ',
						order_id: razorPayOrderId,
						// key: process.env.REACT_APP_RAZORPAY_KEY,
						// amount: totalPrice * 100,
						// currency: 'INR',
						// name: 'Jaisalmer Trust',
						// description:
						// 	'Payment for ticket booking for JaisalmerTrust.org',
						// image:
						// 	'https://www.jaisalmertrust.com/img/logo-dark.png',
						// order_id: razorPayOrderId,
						handler: response => {
							if (response.razorpay_payment_id) {
								orderSuccess({
									orderId,
									...response,
								});
							} else {
								navigate('/order-failed');
							}
						},
						prefill: {
							name: '',
							contact: '+91' + phone,
							email,
						},
						notes: {
							address: '',
						},
						theme: {
							color: '#F37254',
						},



					};

					var rzp1 = new window.Razorpay(options);
					rzp1.open();
				})
				.catch(error => {
					console.log(error);
				});
		}
	};


	const orderSuccess = data => {
		console.log(data);
		axios
			.post(process.env.REACT_APP_API_URL + '/booking-success', data)
			.then(response => {
				console.log(response);
				navigate('/order-success', {
					state: { orderId: response.data.orderId },
				});
			})
			.catch(error => {
				console.log(error);
			});
	};

	return (
		<div>
			<section className="jumbotron breadcumb header">
				<div className="mainbreadcumb">
					<div className="container-fluid">
						<div className="row m-10-hor">
							<div className="col-md-6">
								<h1>Booking</h1>
							</div>
							<div className="col-md-6">
								<div className="list">
									<Link className="link" to="/home">
										Home
									</Link>
									<span className="dash">/</span>
									<span>Booking</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="container-fluid black_more">
				<div className="row m-10-hor">
					<div className="col-md-12">
						<div className="form-side">
							<form
								className="formbooking"
								onSubmit={event => handleSubmit(event)}
								method="post"
							>
								<div className="row">
									<div className="col-md-3">
										<label>Booking Date</label>
										<input
											name="bookingDate"
											type='date'
											min={minDateValue}
											onChange={(event) => {
												let date = moment(event.target.value).format('DD-MM-YYYY');
												setBookingDate(date)
											}
											}
											required
										/>
									</div>
									<div className="col-md-3">
										<label>Email</label>
										<input
											type="email"
											name="email"
											required
											onChange={event =>
												setEmail(event.target.value)
											}
										/>
									</div>
									<div className="col-md-3">
										<label>Mobile Number</label>
										<input
											type="text"
											name="phone"
											onChange={event =>
												setPhone(event.target.value)
											}
										/>
									</div>
									<div className="col-md-3">
										<label>Agent Code</label>
										<input
											type="text"
											name="agentCode"
											onChange={event =>
												setAgentCode(event.target.value)
											}
										/>
									</div>
								</div>
								<div className="row">



									{ticketPrices &&
										ticketPrices.map(ticketPrice => (
											<div
												className="col-md-6"
												key={ticketPrice.id}
											>
												<div className="pricelist">
													<div className="row">
														<div className="col-md-9">
															<div className="heading">
																{
																	ticketPrice.ticketPriceType?.title
																}{' '}
																(
																<div className="bigprice">
																	<span className="text-white">
																		&#8377;
																		{
																			ticketPrice.price
																		}
																	</span>
																</div>
																)
															</div>
															<ul className="list">
																<li>
																	<i className="fa fa-check"></i>{' '}
																	{
																		ticketPrice.ticketPriceType?.description
																	}
																</li>

															</ul>
														</div>
														<div className="col-md-3">
															<div className="row">
																<div className="col-md-12">
																	<input
																		type="text"
																		name={
																			ticketPrice.id
																		}
																		className="form-control text-center"
																		value={
																			bookingDetails[
																				ticketPrice
																					.id
																			]
																				? bookingDetails[
																					ticketPrice
																						.id
																				]
																					.quantity *
																				ticketPrice.price
																				: 0
																		}
																		disabled="disabled"
																	/>
																</div>
																<div className="col-md-12">
																	<div className="input-group actions">
																		<span className="input-group-btn">
																			<button
																				type="button"
																				id="buttonsent"
																				className="icon-button"
																				onClick={() =>
																					manageTicketCount(
																						'increment',
																						ticketPrice.id,
																					)
																				}
																			>
																				<span className="shine"></span>
																				<span>
																					<i className="fa fa-plus"></i>
																				</span>
																			</button>
																		</span>
																		<span className="mx-auto h-100 align-middle">
																			{bookingDetails[
																				ticketPrice
																					.id
																			]
																				? bookingDetails[
																					ticketPrice
																						.id
																				]
																					.quantity
																				: 0}
																		</span>
																		<span className="input-group-btn">
																			<button
																				type="button"
																				id="buttonsent"
																				className="icon-button"
																				onClick={() =>
																					manageTicketCount(
																						'decrement',
																						ticketPrice.id,
																					)
																				}
																			>
																				{/* <span className="shine"></span> */}
																				<span>
																					<i className="fa fa-minus"></i>
																				</span>
																			</button>
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}

									<div className="col-md-12">
										<div className="pricelist">
											<div className="row">
												<div className="col-md-12">
													<div className="heading">
														Booking Summary
													</div>
													<div className="row">
														<div className="col-md-6">
															<ul className="list">
																<li>
																	Total
																	Quantity:{' '}
																	{
																		totalQuantity
																	}
																</li>
															</ul>
														</div>
														<div className="col-md-6">
															<ul className="list">
																<li>
																	Total Price:{' '}
																	&#8377;
																	{totalPrice}
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 text-center">
										<div id="failed" className="hide">
											Message failed...
										</div>
										<button
											type="submit"
											id="buttonsent"
											disabled={totalQuantity === 0}
										>
											<span className="shine"></span>
											<span>Book Now</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};
