import {Link} from '@reach/router';
import React from 'react';
import Footer from '../components/footer';

export default () => (
	<div className="wraperitem">
		<section className="jumbotron breadcumb header">
			<div className="mainbreadcumb">
				<div className="container-fluid">
					<div className="row m-10-hor">
						<div className="col-md-6">
							<h1>Order Failed</h1>
						</div>
						<div className="col-md-6">
							<div className="list">
								<Link className="link" to="/home">
									Home
								</Link>
								<span className="dash">/</span>
								<span>Order Failed</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="container-fluid black_more">
			<div className="row m-10-hor">
				<div className="col-md-12 text-center">
					<h2>You order failed</h2>
					{/* <img src="./img/order-failed.png" alt="" /> */}
				</div>
			</div>
		</section>
		<Footer />
	</div>
);
