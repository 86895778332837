import { Link, navigate } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';
import Loader from '../menu/loader';

export default ({ id }) => {
	const [data, setData] = useState(null);
	useEffect(() => {
		if (id) {
			axios
				.get(process.env.REACT_APP_API_URL + '/get-property/' + id)
				.then(response => {
					setData(response.data);

					// console.log({
					// 	...response.data.banner,
					// 	...response.data.gallery,
					// });
				})
				.catch(error => {
					console.log(error);
					navigate('/');
				});
		} else {
			navigate('/');
		}
	}, [id]);

	return data ? (
		<div className="wraperitem">
			{/* <section className="jumbotron jumbomain">
				<Slider images={data.gallery} />
				<div className="icon-scroll-wraper">
					<div className="icon-scroll">
						<div className="icon-scroll-screen"></div>
					</div>
				</div>
			</section> */}
			<section className="jumbotron breadcumb header">
				<div className="mainbreadcumb">
					<div className="container-fluid">
						<div className="row m-10-hor">
							<div className="col-md-6">
								<h1>{data.title}</h1>
							</div>
							<div className="col-md-6">
								<div className="list">
									<Link className="link" to="/home">
										Home
									</Link>
									<span className="dash">/</span>
									<span>{data.title}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section
				className="container-fluid p-0 black d-flex flex-nowrap bd-highlight"
				id="service"
			>
				<div className="row m-10-hor">
					<div className="col-md-8 col-md-6 pr-md-0 order-1 order-sm-0">
						<div className="left">
							{data.banner ? (
								<div className="mainimg">
									<img src="/img/badabagh-large.png" alt={data.title} />
								</div>
							) : null}

							<div className="content">
								<div className="title">
									About{' '}
									<span className="color">{data.title}</span>
								</div>
								<div
									className="desc"
									dangerouslySetInnerHTML={{
										__html: data.description,
									}}
								/>
							</div>

							{/* <ImageGallery
								images={data.gallery.map(image => ({
									src: image,
									alt: data.title,
									caption: data.title,
									width: 300,
									height: 300,
								}))}
							/> */}
						</div>
					</div>
					<div className="col-md-4 ">
						<div className="right">
							{data.booking ? (
								<div className="bannerservice">
									{/* <div className="imgbg">
										<img
											src="/img/bg-widget.jpg"
											alt="imgservice"
										/>
									</div> */}
									<div className="contb">
										<div className="descb">
											Planning to visit
										</div>
										<div className="headingb">
											{data.title}
										</div>
										<div className="descb">
											Book your ticket now
										</div>
										<div
											className="btn"
											onClick={() =>
												navigate('/book-now', {
													state: { propertyId: id },
												})
											}
										>
											<span className="shine"></span>
											<span>Book Ticket</span>
										</div>
									</div>
								</div>
							) : null}
							<ul className="services-list pl-4 d-none d-sm-block .d-xs-none">
								<li>
									<span className="heading">
										All Properties
									</span>
								</li>
								<li>
									<Link to="/golden-fort">Golden Fort</Link>
								</li>
								<li>
									<Link to="/fort-museum">
										jaisalmer Fort Museum
									</Link>
								</li>
								<li>
									<Link to="/badabagh">Badabagh</Link>
								</li>

								{/* <li>
									<Link to="/jawahar-niwas">
										Jawahar Niwas
									</Link>
								</li>
								<li>
									<Link to="/badabagh">Badabagh</Link>
								</li>
								<li>
									<Link to="/moolsagar">Moolsagar</Link>
								</li>
								<li>
									<Link to="/ghadisar-lake">
										Ghadisar Lake
									</Link>
								</li>
								<li>
									<Link to="/annapurna">Annapurna</Link>
								</li> */}
								{/* <li>
									<Link to="/paiga">Paiga</Link>
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			</section>

			{/* <Testimony /> */}
			<Footer />
		</div>
	) : (
		<Loader />
	);
};
