import { Link, navigate } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';

export default ({ location }) => {
	const { orderId } = location.state;

	console.log(location);

	const [qrcode, setQrcode] = useState(null);

	useEffect(() => {
		if (orderId) {
			axios
				.get(process.env.REACT_APP_API_URL + '/get-ticket/' + orderId)
				.then(response => {
					setQrcode(response.data.ticket);
				})
				.catch(error => {
					console.log(error);
					// navigate('/');
				});
		} else {
			navigate('/');
		}
	}, [orderId]);

	return (
		<div className="wraperitem">
			<section className="jumbotron breadcumb header">
				<div className="mainbreadcumb">
					<div className="container-fluid">
						<div className="row m-10-hor">
							<div className="col-md-6">
								<h1>Order Success</h1>
							</div>
							<div className="col-md-6">
								<div className="list">
									<Link className="link" to="/home">
										Home
									</Link>
									<span className="dash">/</span>
									<span>Order Success</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="container-fluid black_more">
				<div className="row m-10-hor">
					<div className="col-md-12 text-center">
						<h1 class="display-3">Thank You!</h1>
						<p class="lead">
							<strong>Thank you for your booking.</strong> We are
							waiting to welcome you.
						</p>
						<hr />
						<p>
							{qrcode ? (
								<img src={qrcode} alt="" width="200px" />
							) : null}
						</p>
						{/* <p class="lead">
							<button
								onClick={() => window.open(item.link, '_self')}
							>
								<span className="shine"></span>
								<span>Download QR Code</span>
							</button>
						</p> */}
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};
