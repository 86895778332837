import React, { useState } from 'react';
import RazorpayPayment from '../components/razorpay';
import RazorpayScript from '../components/razorpayscrip';

const MobileDonation = () => {

	const [showForm, setShowForm] = useState(false);
	const [donationAmount, setDonationAmount] = useState("");

	const handlePaymentBoxClick = (amount) => {
		setDonationAmount(amount);
	};

	const handleAmountChange = (event) => {
		const enteredAmount = Math.max(1, event.target.value);
		setDonationAmount(enteredAmount);
	};

	const handleDonateButtonClick = () => {
		setShowForm(!showForm);
	};

	return (
		<div>
			<RazorpayScript />
			<div className=''>

				<div id="donateForm" style={{ width: "90%", margin: " auto" }}>
					<div className='donation-box-img-fluid'>
						<img
							src="/img/logo.png"
							className="img-fluid"
							alt="Jaisalmer Trust."
						/>
					</div>
					<div className='custom-amount-text'>
						Quick donation :
					</div>

					<div className='d-flex justify-content-between payment-box-card '>
						<div className='payment-box' onClick={() => handlePaymentBoxClick(100)}>
							100 &#x20B9;
						</div>
						<div className='payment-box' onClick={() => handlePaymentBoxClick(200)}>
							200 &#x20B9;
						</div>
						<div className='payment-box' onClick={() => handlePaymentBoxClick(300)}>
							300 &#x20B9;
						</div>
						<div className='payment-box' onClick={() => handlePaymentBoxClick(400)}>
							400 &#x20B9;
						</div>
						<div className='payment-box' onClick={() => handlePaymentBoxClick(500)}>
							500 &#x20B9;
						</div>
						<div className='payment-box' onClick={() => handlePaymentBoxClick(600)}>
							600 &#x20B9;
						</div>
						<div className='custom-amount-text'>
							Custom Amount :
						</div>
						<div className='payment-input-box text-center'>
							<input
								type='number'
								onChange={handleAmountChange}
								placeholder='Enter donation amount'
								className='donation-box-input'
							/>
						</div>
					</div>
					<div className='mt-5 d-flex  justify-content-around  text-center'>
						<RazorpayPayment donationAmount={donationAmount} />
					</div>

				</div>

			</div>
		</div>

	);
};

export default MobileDonation
