import React from 'react';
import '../../assets/style.scss';
import Footer from '../components/footer';

const RefundPolicy = () => {
	return (
		<>
			<div className="mt-5 mb-5" style={{marginTop: '30px'}}>
				<br></br>

				<div className="container" style={{marginTop: '20px'}}>
					<div className="row d-flex">
						<div className="col-md-8 mt-5 m-auto ">
							<div className="row " style={{paddingTop: '50px'}}>
								<div className="col-md-12 mb-3">
									<h1>Refund Policy</h1>
								</div>

								<div className="col-md-12 ">
								We want you to be completely satisfied with your donation to the Jaisalmer Trust. If you are not satisfied for any reason, please contact us within 30 days of your donation, and we will be happy to issue a refund. However, please note that we are unable to refund any processing fees charged by payment processors. We reserve the right to decline a refund request if we suspect fraudulent or abusive behaviour.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default RefundPolicy;
