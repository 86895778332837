import { Link, navigate } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';
import Loader from '../menu/loader';

export default ({ id }) => {
	const [data, setData] = useState(null);

	useEffect(() => {
		if (id) {
			axios
				.get(process.env.REACT_APP_API_URL + '/get-property/' + id)
				.then(response => {
					setData(response.data);

					// console.log({
					// 	...response.data.banner,
					// 	...response.data.gallery,
					// });
				})
				.catch(error => {
					console.log(error);
					navigate('/');
				});
		} else {
			navigate('/');
		}
	}, [id]);

	return data ? (
		<div className="wraperitem">
			{/* <section className="jumbotron jumbomain">
				<Slider images={data.gallery} />
				<div className="icon-scroll-wraper">
					<div className="icon-scroll">
						<div className="icon-scroll-screen"></div>
					</div>
				</div>
			</section> */}
			<section className="jumbotron breadcumb header">
				<div className="mainbreadcumb">
					<div className="container-fluid">
						<div className="row m-10-hor">
							<div className="col-md-6">
								<h1>{data.title}</h1>
							</div>
							<div className="col-md-6">
								<div className="list">
									<Link className="link" to="/home">
										Home
									</Link>
									<span className="dash">/</span>
									<span>{data.title}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <div className="col-md-10 p-5"></div> */}
			<div className="col-md-12 text-center p-5">
				<div className="heading">Welcome to Golden Fort</div>
				{/* <div className="subheading">According lifestyle</div> */}
			</div>
			<section className="container-fluid p-0 black d-flex flex-nowrap bd-highlight">
				<div className="row">
					<div className="col-md-6 pr-md-0 order-0 order-sm-0">
						<img
							src="/img/golden-fort.png"
							className="imgslickz"
							alt="Golden Fort"
						/>
					</div>
					<div className="col-md-6 p-5 order-1 order-sm-1">
						<div className="p-md-5">
							<div className="heading">Golden Fort</div>

							<p className="mt-3">
								Jaisalmer Fort is situated in the city of
								Jaisalmer, in the Indian state of Rajasthan. It
								is believed to be one of the very few 'living
								forts' in the world, as nearly one fourth of the
								old city's population still resides within the
								fort. For the better part of its 800-year
								history, the fort was the city of Jaisalmer.
							</p>
							{/* <Link className="btn" to="/golden-fort">
								<span className="shine"></span>
								<span>View Details</span>
							</Link> */}
						</div>
					</div>
					<div className="col-md-6 centered p-5 pb-5 order-3 order-sm-2">
						<div className="p-md-5">
							<div className="heading">Raja Ka Mahal</div>
							<p className="mt-3">
								Raja Ka Mahal is situated in the city of
								Jaisalmer, in the Indian state of Rajasthan. It
								is believed to be one of the very few 'living
								forts' in the world, as nearly one fourth of the
								old city's population still resides within the
								fort. For the better part of its 800-year
								history, the fort was the city of Jaisalmer.
							</p>
							{/* <Link className="btn" to="/raja-ka-mahal">
								<span className="shine"></span>
								<span>View Details</span>
							</Link> */}
						</div>
					</div>
					<div className="col-md-6 pl-md-0 order-2 order-sm-3">
						<img
							src="/img/raja-ka-mahal.png"
							className="imgslickz"
							alt="Raja Ka Mahal"
						/>
					</div>
					<div className="col-md-6 pr-md-0 order-4 order-sm-4">
						<img
							src="/img/rani-ka-mahal.png"
							className="imgslickz"
							alt="Rani Ka Mahal"
						/>
					</div>
					<div className="col-md-6 p-5 order-5 order-sm-5">
						<div className="p-md-5">
							<div className="heading">Rani Ka Mahal</div>
							<p className="mt-3">
								Rani Ka Mahal is situated in the city of
								Jaisalmer, in the Indian state of Rajasthan. It
								is believed to be one of the very few 'living
								forts' in the world, as nearly one fourth of the
								old city's population still resides within the
								fort. For the better part of its 800-year
								history, the fort was the city of Jaisalmer.
							</p>
							{/* <Link className="btn" to="/badabagh">
								<span className="shine"></span>
								<span>View Details</span>
							</Link> */}
						</div>
					</div>
					<div className="col-md-6 p-5 order-7 order-sm-6">
						<div className="p-md-5">
							<div className="heading">Paiga</div>
							<p className="mt-3">
								Paiga is situated in the city of Jaisalmer, in
								the Indian state of Rajasthan. It is believed to
								be one of the very few 'living forts' in the
								world, as nearly one fourth of the old city's
								population still resides within the fort. For
								the better part of its 800-year history, the
								fort was the city of Jaisalmer.
							</p>
						</div>
					</div>
					<div className="col-md-6 pr-md-0 order-6 order-sm-7">
						<img
							src="/img/paiga-large.png"
							className="imgslickz"
							alt="Paiga"
						/>
					</div>

					<div className="col-md-6 pr-md-0 order-8 order-sm-8">
						<img
							src="/img/annapurna-large.png"
							className="imgslickz"
							alt="Annapurna"
						/>
					</div>
					<div className="col-md-6 p-5 order-9 order-sm-9">
						<div className="p-md-5">
							<div className="heading">Annapurna</div>
							<p className="mt-3">
								Annapurna is situated in the city of Jaisalmer,
								in the Indian state of Rajasthan. It is believed
								to be one of the very few 'living forts' in the
								world, as nearly one fourth of the old city's
								population still resides within the fort. For
								the better part of its 800-year history, the
								fort was the city of Jaisalmer.
							</p>
						</div>
					</div>
				</div>
			</section>

			{/* <section className="container-fluid" id="service"> */}
			{/* <div className="row m-10-hor"> */}
			{/* <div className="col-md-8"> */}
			{/* <div className="left"> */}
			{/* {data.banner ? (
								<div className="mainimg">
									<img src={data.banner} alt={data.title} />
								</div>
							) : null} */}

			{/* <div className="content">
								<div className="title">
									About{' '}
									<span className="color">{data.title}</span>
								</div>
								<div
									className="desc"
									dangerouslySetInnerHTML={{
										__html: data.description,
									}}
								/>
							</div> */}
			{/* <ImageGallery
								images={data.gallery.map(image => ({
									src: image,
									alt: data.title,
									caption: data.title,
									width: 300,
									height: 300,
								}))}
							/> */}
			{/* </div> */}
			{/* </div> */}
			{/* <div className="col-md-4"> */}
			{/* <div className="right"> */}
			{/* {data.booking ? ( */}
			{/* <div className="bannerservice"> */}
			{/* <div className="imgbg">
										<img
											src="/img/bg-widget.jpg"
											alt="imgservice"
										/>
									</div> */}
			{/* <div className="contb">
										<div className="descb">
											Planning to visit
										</div>
										<div className="headingb">
											{data.title}
										</div>
										<div className="descb">
											Book your ticket now
										</div>
										<div
											className="btn"
											onClick={() =>
												navigate('/book-now', {
													state: {propertyId: id},
												})
											}
										>
											<span className="shine"></span>
											<span>Book Ticket</span>
										</div>
									</div> */}
			{/* </div> */}
			{/* ) : null} */}
			{/* <ul className="services-list">
								<li>
									<span className="heading">
										All Properties
									</span>
								</li>
								<li>
									<Link to="/golden-fort">Golden Fort</Link>
								</li>
								<li>
									<Link to="/golden-fort">Golden Fort</Link>
								</li>
								<li>
									<Link to="/fort-museum">Fort Museum</Link>
								</li>

								<li>
									<Link to="/jawahar-niwas">
										Jawahar Niwas
									</Link>
								</li>
								<li>
									<Link to="/badabagh">Badabagh</Link>
								</li>
								<li>
									<Link to="/moolsagar">Moolsagar</Link>
								</li>
								<li>
									<Link to="/ghadisar-lake">
										Ghadisar Lake
									</Link>
								</li>
								<li>
									<Link to="/annapurna">Annapurna</Link>
								</li>
								<li>
									<Link to="/badabagh">Badabagh</Link>
								</li>
							</ul> */}
			{/* </div> */}
			{/* </div> */}
			{/* </div> */}
			{/* </section> */}
			{/* <Testimony /> */}
			<Footer />
		</div>
	) : (
		<Loader />
	);
};
