import { Link } from '@reach/router';
import React from 'react';
import Footer from '../components/footer';

export default () => (
	<div>
		<header className="jumbotron breadcumb h-vh">
			<div className="overlay"></div>
			<video
				playsInline="playsinline"
				autoPlay="autoplay"
				muted="muted"
				loop="loop"
			>
				<source src="./video/homepage.m4v" type="video/mp4" />
			</video>
			<div className="homebread">
				<div className="container-fluid">
					<div className="row m-10-hor">
						<div className="col-md-10 mx-auto text-center ">
							<div className="content mx-auto">
								<img src="/img/logo.png" className="w-50" alt='logo' />
							</div>
							<div className="content mx-auto">
								Official Portal of
							</div>
							<div className="heading">
								World's only living fort!
							</div>
							<div
								className="btn"
								onClick={() =>
									window.open(
										'https://youtu.be/Q23OOnD3uaI',
										'_blank',
									)
								}
							>
								<span className="shine"></span>
								<span>Watch Video</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<section className="container-fluid black_more">
			<div className="row m-10-hor text-center">
				<div className="col-md-12 ">
					<div className="heading">Welcome to Jaisalmer Trust</div>
					{/* <div className="subheading">According lifestyle</div> */}
				</div>

				{/* <div className="col-md-12">
					<div className="content">
						Curabitur mollis bibendum luctus. Duis suscipit vitae
						dui sed suscipit. Vestibulum auctor nunc vitae diam
						eleifend, in maximus metus sollicitudin. Quisque vitae
						sodales lectus. Nam porttitor justo sed mi finibus, vel
						tristique risus faucibus. Curabitur mollis bibendum
						luctus. Duis suscipit vitae dui sed suscipit. Vestibulum
						auctor nunc vitae diam eleifend, in maximus metus
						sollicitudin. Quisque vitae sodales lectus. Nam
						porttitor justo sed mi finibus, vel tristique risus
						faucibus.
					</div>
					<div className="content">
						Curabitur mollis bibendum luctus. Duis suscipit vitae
						dui sed suscipit. Quisque vitae sodales lectus, vel
						tristique risus faucibus.
					</div>
					<div className="content">
						Curabitur mollis bibendum luctus. Duis suscipit vitae
						dui sed suscipit. Vestibulum auctor nunc vitae diam
						eleifend, in maximus metus sollicitudin. Quisque vitae
						sodales lectus. Nam porttitor justo sed mi finibus, vel
						tristique risus faucibus.
					</div>
					<div className="content">
						Curabitur mollis bibendum luctus. Duis suscipit vitae
						dui sed suscipit. Quisque vitae sodales lectus, vel
						tristique risus faucibus.
					</div>
				</div> */}
			</div>
		</section>

		<section className="container-fluid p-0 black d-flex flex-nowrap bd-highlight">
			<div className="row">
				<div className="col-md-6 pr-md-0 order-0 order-sm-0 ">
					<img
						src="/img/golden-fort.png"
						className="imgslickz"
						alt="Golden Fort"
					/>
				</div>
				<div className="col-md-6 p-5 order-1 order-sm-1">
					<div className="p-md-5">
						<div className="heading">Golden Fort</div>

						<p className="mt-3">
							Jaisalmer Fort is situated in the city of Jaisalmer,
							in the Indian state of Rajasthan. It is believed to
							be one of the very few 'living forts' in the world,
							as nearly one fourth of the old city's population
							still resides within the fort. For the better part
							of its 800-year history, the fort was the city of
							Jaisalmer.
						</p>
						<Link className="btn" to="/golden-fort">
							<span className="shine"></span>
							<span>View Details</span>
						</Link>
					</div>
				</div>
				<div className="col-md-6 centered p-5 pb-5 order-3 order-sm-2">
					<div className="p-md-5">
						<div className="heading">Jaisalmer Fort Museum</div>
						<p className="mt-3">
							Jaisalmer Fort Museum is situated in the city of
							Jaisalmer, in the Indian state of Rajasthan. It is
							believed to be one of the very few 'living forts' in
							the world, as nearly one fourth of the old city's
							population still resides within the fort. For the
							better part of its 800-year history, the fort was
							the city of Jaisalmer.
						</p>
						<Link className="btn" to="/museum">
							<span className="shine"></span>
							<span>View Details</span>
						</Link>
					</div>
				</div>
				<div className="col-md-6 pl-md-0 order-2 order-sm-3">
					<img
						src="/img/golden-fort.png"
						className="imgslickz"
						alt="Jaisalmer Fort Museum"
					/>
				</div>
				<div className="col-md-6 pr-md-0 order-4 order-sm-4">
					<img
						src="/img/badabagh-large.png"
						className="imgslickz"
						alt="Badabagh"
					/>
				</div>
				<div className="col-md-6 p-5 order-5 order-sm-5">
					<div className="p-md-5">
						<div className="heading">Badabagh</div>
						<p className="mt-3">
							Badabagh is situated in the city of Jaisalmer, in
							the Indian state of Rajasthan. It is believed to be
							one of the very few 'living forts' in the world, as
							nearly one fourth of the old city's population still
							resides within the fort. For the better part of its
							800-year history, the fort was the city of
							Jaisalmer.
						</p>
						<Link className="btn" to="/badabagh">
							<span className="shine"></span>
							<span>View Details</span>
						</Link>
					</div>
				</div>
			</div>
		</section>
		{/* <section className="container-fluid p-0">
			<div className="row">
				<div className="col-md-4 p-0">
					<div className="features">
						<div className="bg">
							<img
								src="/img/jawahar-niwas.png"
								alt="Jawahar Niwas"
							/>
						</div>
						<div className="content">
							<div className="heading">Jawahar Niwas</div>
							<div className="con-text">
								Jawahar Niwas is situated in the city of
								Jaisalmer, in the Indian state of Rajasthan. It
								is believed to be one of the very few 'living
								forts' in the world, as nearly one fourth of the
								old city's population still resides within the
								fort. For the better part of its 800-year
								history, the fort was the city of Jaisalmer.
							</div>
							<div className="link">
								<Link to="/jawahar-niwas">
									<span className="shine"></span>
									View Details
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4 p-0">
					<div className="features">
						<div className="bg">
							<img src="/img/badabagh.png" alt="Badabagh" />
						</div>
						<div className="content">
							<div className="heading">Badabagh</div>
							<div className="con-text">
								Badabagh is situated in the city of Jaisalmer,
								in the Indian state of Rajasthan. It is believed
								to be one of the very few 'living forts' in the
								world, as nearly one fourth of the old city's
								population still resides within the fort. For
								the better part of its 800-year history, the
								fort was the city of Jaisalmer.
							</div>
							<div className="link">
								<Link to="/badabagh">
									<span className="shine"></span>
									View Details
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4 p-0">
					<div className="features">
						<div className="bg">
							<img src="/img/moolsagar.png" alt="Moolsagar" />
						</div>
						<div className="content">
							<div className="heading">Moolsagar</div>
							<div className="con-text">
								Moolsagar is situated in the city of Jaisalmer,
								in the Indian state of Rajasthan. It is believed
								to be one of the very few 'living forts' in the
								world, as nearly one fourth of the old city's
								population still resides within the fort. For
								the better part of its 800-year history, the
								fort was the city of Jaisalmer.
							</div>
							<div className="link">
								<Link to="/moolsagar">
									<span className="shine"></span>
									View Details
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4 p-0">
					<div className="features">
						<div className="bg">
							<img
								src="/img/ghadisar-lake.png"
								alt="Ghadisar Lake"
							/>
						</div>
						<div className="content">
							<div className="heading">Ghadisar Lake</div>
							<div className="con-text">
								Ghadisar Lake is situated in the city of
								Jaisalmer, in the Indian state of Rajasthan. It
								is believed to be one of the very few 'living
								forts' in the world, as nearly one fourth of the
								old city's population still resides within the
								fort. For the better part of its 800-year
								history, the fort was the city of Jaisalmer.
							</div>
							<div className="link">
								<Link to="/ghadisar-lake">
									<span className="shine"></span>
									View Details
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4 p-0">
					<div className="features">
						<div className="bg">
							<img src="/img/annapurna.png" alt="Annapurna" />
						</div>
						<div className="content">
							<div className="heading">Annapurna</div>
							<div className="con-text">
								Jaisalmer Fort is situated in the city of
								Jaisalmer, in the Indian state of Rajasthan. It
								is believed to be one of the very few 'living
								forts' in the world, as nearly one fourth of the
								old city's population still resides within the
								fort. For the better part of its 800-year
								history, the fort was the city of Jaisalmer.
							</div>
							<div className="link">
								<Link to="/annapurna">
									<span className="shine"></span>
									View Details
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4 p-0">
					<div className="features">
						<div className="bg">
							<img src="/img/paiga.png" alt="Paiga" />
						</div>
						<div className="content">
							<div className="heading">Paiga</div>
							<div className="con-text">
								Paiga is situated in the city of Jaisalmer, in
								the Indian state of Rajasthan. It is believed to
								be one of the very few 'living forts' in the
								world, as nearly one fourth of the old city's
								population still resides within the fort. For
								the better part of its 800-year history, the
								fort was the city of Jaisalmer.
							</div>
							<div className="link">
								<Link to="/paiga">
									<span className="shine"></span>
									View Details
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> */}

		{/* <section className="container-fluid black_more">
			<div className="row m-10-hor">
				<div className="col-12 text-center">
					<div className="heading">About Jaisalmer Trust</div>
					<div className="row">
						<div className="col-md-8 mx-auto">
							<p className="content">
								Curabitur mollis bibendum luctus. Duis suscipit
								vitae dui sed suscipit. Vestibulum auctor nunc
								vitae diam eleifend, in maximus metus
								sollicitudin. Quisque vitae sodales lectus. Nam
								p orttitor justo sed mi finibus, vel tristique
								risus faucibus.
							</p>
						</div>
					</div>
				</div>
				<Carouselteam />
			</div>
		</section> */}
		<Footer />
	</div>
);
