import { Link } from '@reach/router';
import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import Breakpoint, {
	BreakpointProvider,
	setDefaultBreakpoints,
} from 'react-socks';
import RazorpayScript from '../components/razorpayscrip';
setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = props => (
	<Link
		{...props}
		getProps={({ isCurrent }) => {
			// the object returned here is passed to the
			// anchor element's props
			return {
				className: isCurrent ? 'active' : 'non-active',
			};
		}}
	/>
);



export default () => {
	const [openMenu, setOpenMenu] = React.useState(false);
	const [openMenu1, setOpenMenu1] = React.useState(false);
	const handleBtnClick = () => {
		setOpenMenu(!openMenu);
	};
	const handleBtnClick1 = () => {
		setOpenMenu1(!openMenu1);
	};
	const closeMenu = () => {
		setOpenMenu(false);
	};
	const closeMenu1 = () => {
		setOpenMenu1(false);
	};
	const ref = useOnclickOutside(() => {
		closeMenu();
	});
	const ref1 = useOnclickOutside(() => {
		closeMenu1();
	});

	const [showmenu, btn_icon] = useState(false);

	useEffect(() => {
		const header = document.getElementById('myHeader');
		const totop = document.getElementById('scroll-to-top');
		const sticky = header.offsetTop;
		const scrollCallBack = window.addEventListener('scroll', () => {
			btn_icon(false);
			if (window.pageYOffset > sticky + 300) {
				header.classList.add('sticky');
				totop.classList.add('show');
			} else {
				header.classList.remove('sticky');
				totop.classList.remove('show');
			}
			if (window.pageYOffset > sticky) {
				closeMenu();
			}
		});
		return () => {
			window.removeEventListener('scroll', scrollCallBack);
		};
	}, []);

	const [showForm, setShowForm] = useState(false);

	const [donationAmount, setDonationAmount] = useState("");

	const handlePaymentBoxClick = (amount) => {
		setDonationAmount(amount);
	};

	const handleAmountChange = (event) => {
		const enteredAmount = Math.max(1, event.target.value);
		setDonationAmount(enteredAmount);
	};

	const renderForm = () => {
		if (showForm) {
			return (
				<div className=''>

					<div id="donateForm" className="custom-donation-form">
						<div className='donation-box-img-fluid'>
							<img
								src="/img/logo.png"
								className="img-fluid"
								alt="Jaisalmer Trust."
							/>
						</div>
						<div className='custom-amount-text'>
							Quick donation :
						</div>
						<div className='d-flex justify-content-between payment-box-card '>
							<div className='payment-box' onClick={() => handlePaymentBoxClick(100)}>
								100 &#x20B9;
							</div>
							<div className='payment-box' onClick={() => handlePaymentBoxClick(200)}>
								200 &#x20B9;
							</div>
							<div className='payment-box' onClick={() => handlePaymentBoxClick(300)}>
								300 &#x20B9;
							</div>
							<div className='payment-box' onClick={() => handlePaymentBoxClick(400)}>
								400 &#x20B9;
							</div>
							<div className='payment-box' onClick={() => handlePaymentBoxClick(500)}>
								500 &#x20B9;
							</div>
							<div className='payment-box' onClick={() => handlePaymentBoxClick(600)}>
								600 &#x20B9;
							</div>
							<div className='custom-amount-text'>
								Custom Amount :
							</div>
							<div className='payment-input-box text-center'>
								<input
									type='number'
									onChange={handleAmountChange}
									placeholder='Enter donation amount'
									className='donation-box-input'
								/>
							</div>
						</div>
						{/* <div className='mt-5 d-flex  justify-content-around  text-center'>
							<div className=''>
								<RazorpayPayment donationAmount={donationAmount} />
							</div>
							<div className='navbar-item RazorPay-Payment-Button pt-2 pb-2' onClick={handleDonateButtonClick}>
								Cancel
							</div>
						</div> */}

					</div>

				</div>

			);
		}
		return null;
	};

	// Step 4: Add event handler for the "Donate" button
	const handleDonateButtonClick = () => {
		setShowForm(!showForm);
		if (showmenu) {
			btn_icon(!showmenu); // Close the menu if it is open
		}
	};



	return (
		<header id="myHeader" className="navbar">
			<RazorpayScript />
			<div className="container-fluid">
				<div className="row m-2-hor w-100-nav">
					<div className="logo">
						<div className="navbar-title navbar-item">
							<NavLink to="/">
								<img
									src="/img/logo.png"
									className="img-fluid"
									alt="Jaisalmer Trust."
								/>
							</NavLink>
						</div>
					</div>

					<BreakpointProvider>
						<Breakpoint l down>
							{renderForm()}

							{showmenu && (
								<div className="menu">
									<div className="navbar-item">
										<NavLink
											to="/about"
											onClick={() => btn_icon(!showmenu)}
										>
											Home
										</NavLink>
									</div>
									{/* <div className="navbar-item">
										<NavLink
											to="/about"
											onClick={() => btn_icon(!showmenu)}
										>
											About
										</NavLink>
									</div> */}
									<div className="navbar-item">

										<div ref={ref}>
											<div
												className="dropdown-custom dropdown-toggle btn"
												onClick={handleBtnClick}
											>
												About
											</div>

											{openMenu && (
												<div className="item-dropdown">
													<div
														className="dropdown"
														onClick={closeMenu}
													>
														<NavLink
															to="/golden-fort"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Golden Fort
														</NavLink>

														<NavLink
															to="/museum"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Jaisalmer Fort
															Museum
														</NavLink>
														<NavLink
															to="/badabagh"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Badabagh
														</NavLink>
													</div>
												</div>
											)}
										</div>
									</div>

									{/* <div className="navbar-item">
										<div ref={ref}>
											<div
												className="dropdown-custom dropdown-toggle btn"
												onClick={handleBtnClick}
											>
												Main Attraction
											</div>
											{openMenu && (
												<div className="item-dropdown">
													<div
														className="dropdown"
														onClick={closeMenu}
													>
														<NavLink
															to="/golden-fort"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Golden Fort
														</NavLink>
														<NavLink
															to="/raja-ka-mahal"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Raja Ka Mahal
														</NavLink>
														<NavLink
															to="/rani-ha-mahal"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Rani Ka Mahal
														</NavLink>
													</div>
												</div>
											)}
										</div>
									</div> */}
									{/* <div className="navbar-item">
										<div ref={ref1}>
											<div
												className="dropdown-custom dropdown-toggle btn"
												onClick={handleBtnClick1}
											>
												Other Places
											</div>
											{openMenu1 && (
												<div className="item-dropdown">
													<div
														className="dropdown"
														onClick={closeMenu1}
													>
														<NavLink
															to="/jawahar-niwas"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Jawahar Niwas
														</NavLink>
														<NavLink
															to="/badabagh"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Badabagh
														</NavLink>
														<NavLink
															to="/moolsagar"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Moolsagar
														</NavLink>
														<NavLink
															to="/ghadisar-lake"
															onClick={() =>
																btn_icon(
																	!showmenu,
																	)
															}
														>
															Ghadisar Lake
														</NavLink>
														<NavLink
															to="/annapurna"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Annapurna
														</NavLink>
														<NavLink
															to="/paiga"
															onClick={() =>
																btn_icon(
																	!showmenu,
																)
															}
														>
															Paiga
														</NavLink>
													</div>
												</div>
											)}
										</div>
									</div> */}
									<div className="navbar-item">
										<NavLink
											to="/contact"
											onClick={() => btn_icon(!showmenu)}
										>
											Contact
										</NavLink>
									</div>
									{/* <div className="navbar-item">

										<div className="btn"
											onClick={handleDonateButtonClick}
										>

											<span>Donate</span>

										</div>

									</div> */}


								</div>
							)}
						</Breakpoint>

						<Breakpoint xl>
							<div className="menu">
								<div className="navbar-item">
									<NavLink to="/">Home</NavLink>
								</div>
								{/* <div className="navbar-item">
									<NavLink to="/about">About Trust</NavLink>
								</div> */}

								<div className="navbar-item">
									<div ref={ref}>
										<div
											className="dropdown-custom dropdown-toggle btn"
											onMouseEnter={handleBtnClick}
											onMouseLeave={closeMenu}
										>
											About
											{openMenu && (
												<div className="item-dropdown">
													<div
														className="dropdown"
														onClick={closeMenu}
													>
														<NavLink to="/golden-fort">
															Golden Fort
														</NavLink>
														<NavLink to="/museum">
															Jaisalmer Fort
															Museum
														</NavLink>
														<NavLink to="/badabagh">
															Badabagh
														</NavLink>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>

								{/* <div className="navbar-item">
									<div ref={ref}>
										<div
											className="dropdown-custom dropdown-toggle btn"
											onMouseEnter={handleBtnClick}
											onMouseLeave={closeMenu}
										>
											Main Attraction
											{openMenu && (
												<div className="item-dropdown">
													<div
														className="dropdown"
														onClick={closeMenu}
													>
														<NavLink to="/golden-fort">
															Golden Fort
														</NavLink>
														<NavLink to="/raja-ka-mahal">
															Raja Ka Mahal
														</NavLink>
														<NavLink to="/rani-ka-mahal">
															Rani Ka Mahal
														</NavLink>
													</div>
												</div>
											)}
										</div>
									</div>
								</div> */}
								{/* <div className="navbar-item">
									<div ref={ref1}>
										<div
											className="dropdown-custom dropdown-toggle btn"
											onMouseEnter={handleBtnClick1}
											onMouseLeave={closeMenu1}
										>
											Other Places
											{openMenu1 && (
												<div className="item-dropdown">
													<div
														className="dropdown"
														onClick={closeMenu1}
													>
														<NavLink to="/jawahar-niwas">
															Jawahar Niwas
														</NavLink>
														<NavLink to="/badabagh">
															Badabagh
														</NavLink>
														<NavLink to="/moolsagar">
															Moolsagar
														</NavLink>
														<NavLink to="/ghadisar-lake">
															Ghadisar Lake
														</NavLink>
														<NavLink to="/annapurna">
															Annapurna
														</NavLink>
														<NavLink to="/paiga">
															Paiga
														</NavLink>
													</div>
												</div>
											)}
										</div>
									</div>
								</div> */}
								<div className="navbar-item">
									<NavLink to="/contact">Contact</NavLink>
								</div>


								{/* <div className="navbar-item">

									<div className="btn"
										onClick={handleDonateButtonClick}
									>

										<span>Donate</span>

									</div>
									{renderForm()}
								</div> */}


							</div>
						</Breakpoint>
					</BreakpointProvider>
				</div>

				<button
					className="nav-icon"
					onClick={() => btn_icon(!showmenu)}
				>
					<div className="menu-line white"></div>
					<div className="menu-line1 white"></div>
					<div className="menu-line2 white"></div>
				</button>
			</div>
		</header>
	);
};
