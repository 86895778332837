import React from 'react';
import {Link} from '@reach/router';

export default () => (
	<footer className="container-fluid black_more">
		<div className="bg-footer">
			<img src="/img/footer-img.jpg" alt="Jaisalmer Trust" />
		</div>
		<div className="row m-10-hor">
			<div className="col-md-3">
				<div className="footer-col">
					<img src="/img/logo.png" className="img-fluid" alt="#" />
				</div>
			</div>
			<div className="col-md-4">
				<div className="footer-col">
					<div className="heading">
						<h2 className="font-weight-bold ">Jaisalmer Trust</h2>
					</div>
					<div className="content">
						<div className="list">
							<i className="fa fa-map-marker"></i>
							Jaisalmer Trust, Fort Rd, near Gopa Chowk, Dhibba
							Para, Manak Chowk, Amar Sagar Pol, Jaisalmer,
							Rajasthan 345001
						</div>
						<div className="list">
							<i className="fa fa-envelope-o"></i>
							<a
								href="mailto:contact@jaisalmertrust.org"
								target="_blank"
								rel="noopener noreferrer"
							>
								contact@jaisalmertrust.org
							</a>
						</div>
						<div className="list">
							<i className="fa fa-phone"></i>
							+91-96XXXXXX82
						</div>
					</div>
					<div className="content">
						<div className="socialicon">
							<span className="shine"></span>
							<i className="fa fa-facebook-f"></i>
						</div>
						{/* <div className="socialicon">
							<span className="shine"></span>
							<i className="fa fa-linkedin"></i>
						</div>
						<div className="socialicon">
							<span className="shine"></span>
							<i className="fa fa-twitter"></i>
						</div> */}
						<div className="socialicon">
							<span className="shine"></span>
							<i className="fa  fa-instagram"></i>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-3">
				<div className="footer-col">
					<div className="heading">Download Mobile App</div>
					<div className="content">
						<div className="img">
							<img
								src="/img/android.png"
								className="img-fluid"
								alt="Jaisalmer Trust - Android Mobile Application"
							/>
						</div>
						<div className="img">
							<img
								src="/img/ios.png"
								className="img-fluid"
								alt="Jaisalmer Trust - IOS Mobile Application"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-2">
				<div className="footer-col">
					<div className="heading">Other Links</div>
					<div className="content">
						<div className="link">
							<Link to="/terms-and-condition" style={{color: 'white', textDecoration: 'none'}}>
								Terms & Conditions
							</Link>
						</div>
						<div className="link" >
							<Link to="/privacyPolicy" style={{color: 'white', textDecoration: 'none'}}>Privacy Policy</Link>
						</div>
						<div className="link">
							<Link to="/refundPolicy" style={{color: 'white', textDecoration: 'none'}}>Refund Policy</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="subfooter">
			<div className="row m-10-hor">
				<div className="col-md-6">
					<div className="content">
						© Copyrights 2020{' '}
						<span className="font-weight-bold">
							Jaisalmer Trust
						</span>{' '}
						All rights reserved.
					</div>
				</div>
			</div>
		</div>
	</footer>
);
