import React from 'react';
import '../../assets/style.scss';
import Footer from '../components/footer';

const PrivacyPolicy = () => {
	return (
		<>
			<div className="mt-5 mb-5" style={{marginTop: '30px'}}>
				<br></br>

				<div className="container" style={{marginTop: '20px'}}>
					<div className="row d-flex">
						<div className="col-md-8 mt-5 m-auto ">
							<div className="row " style={{paddingTop: '50px'}}>
								<div className="col-md-12 mb-3">
									<h1>Privacy Policy</h1>
								</div>

								<div className="col-md-12 ">
									At Jaisalmer Trust, we value your privacy
									and are committed to protecting your
									personal information. We collect, store, and
									use your data only for the purpose of
									providing our services to you. We do not
									share or sell your information to third
									parties without your explicit consent unless
									required by law. We implement
									industry-standard security measures to
									protect your information from unauthorised
									access, disclosure, alteration, or
									destruction. By using our website, you agree
									to our privacy policy.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default PrivacyPolicy;
