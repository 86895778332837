import React from 'react';
import '../../assets/style.scss';
import Footer from '../components/footer';

const TermsAndConditions = () => {
	return (
		<>
			<div className="mt-5" style={{marginTop: '30px'}}>
				<br></br>

				<div className="container" style={{marginTop: '20px'}} >
					<div className="row d-flex m-auto" >
						<div className="col-md-4 mt-5 ImageLogo" >

						</div>

						<div className="col-md-8 mt-5 JaisalmerLeftAlignText" style={{display: 'contents'}}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<h1>Terms And Conditions</h1>
								</div>
								<div className="col-md-12">
								These terms and conditions outline the rules and regulations for the use of Jaisalmer Trust's website, located at https://jaisalmertrust.com/. By accessing this website, you accept and agree to be bound by these terms and conditions. If you do not agree to all of the terms and conditions of this agreement, then you may not access the website or use any of its services.

<ul></ul>
Website Content:The information, materials, and content provided on the Jaisalmer Trust website are for general information purposes only. We reserve the right to change, modify, add, or remove any part of the content without prior notice.

<ul></ul>
Intellectual Property:The website's text, graphics, logos, images, and software are owned by Jaisalmer Trust and are protected under relevant intellectual property laws, including copyright and trademark laws. You are not allowed to use, reproduce, modify, distribute, or display any part of the content without our prior written permission.

<ul></ul>
Third-Party Websites:Jaisalmer Trust may include links to websites owned or controlled by third parties on this website.We are not responsible for the content or practices of these websites, and you access them at your own risk.
User Conduct:You agree to use the website in a manner that does not violate any applicable laws or regulations or infringe on the rights of any third party. You agree not to engage in any conduct that could damage, disable, or impair the website or its services.
<ul></ul>
Privacy:We value your privacy and take all necessary measures to protect it. Please review our Privacy Policy for information on how we collect, use, and protect your personal information.


<ul></ul>
Disclaimer:The content on this website is provided "as is" without any warranty, either express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose. Jaisalmer Trust does not guarantee the accuracy, completeness, or timeliness of the content on the website, and is not liable for any errors or omissions in the content.


<ul></ul>
Limitation of Liability : Jaisalmer Trust shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use the website or its services, even if we have been advised of the possibility of such damages.
<ul></ul>
Indemnification: You agree to indemnify and hold Jaisalmer Trust, its affiliates, and their respective officers, directors, employees, and agents harmless from any and all claims, damages, expenses, and liabilities, including reasonable attorneys' fees, arising from your use of the website or its services or your breach of these terms and conditions.
<ul></ul>
Governing Law:These terms and conditions shall be governed by and construed in accordance with the laws of India, and any disputes arising out of or in connection with the use of the website shall be resolved through arbitration in accordance with the rules of the Indian Arbitration and Conciliation Act, 1996.
Changes to Terms and Conditions:We reserve the right to update or modify these terms and conditions at any time without prior notice. Your continued use of the website after any such changes constitutes your acceptance of the new terms and conditions.
<ul></ul>


If you have any questions about these terms and conditions, please contact us at contact@jaisalmertrust.org.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default TermsAndConditions;
