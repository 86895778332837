import { Link } from '@reach/router';
import emailjs from 'emailjs-com';
import React from 'react';
import Footer from '../components/footer';

export default () => {
	const sendEmail = e => {
		const success = document.getElementById('success');
		const button = document.getElementById('buttonsent');
		const failed = document.getElementById('failed');
		e.preventDefault();

		emailjs
			.sendForm(
				'gmail',
				'template_csfdEZiA',
				e.target,
				'user_zu7p2b3lDibMCDutH5hif',
			)
			.then(
				result => {
					console.log(result.text);
					success.classList.add('show');
					button.classList.add('show');
					failed.classList.remove('show');
				},
				error => {
					console.log(error.text);
					failed.classList.add('show');
				},
			);
	};

	return (
		<div>
			<section className="jumbotron breadcumb header">
				<div className="mainbreadcumb">
					<div className="container-fluid">
						<div className="row m-10-hor">
							<div className="col-md-6">
								<h1>Contact</h1>
							</div>
							<div className="col-md-6">
								<div className="list">
									<Link className="link" to="/home">
										Home
									</Link>
									<span className="dash">/</span>
									<span>Contact</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="container-fluid black_more">
				<div className="row m-10-hor">
					<div className="col-md-6">
						<div className="text-side">
							<h2>Get in Touch</h2>
							<p>We love to hear from you.</p>

							<div className="address">
								<div className="heading">Our Office</div>
								<div className="list">
									<i className="fa fa-map-marker"></i>
									Jaisalmer Trust, Fort Rd, near Gopa Chowk,
									Dhibba Para, Manak Chowk, Amar Sagar Pol,
									Jaisalmer, Rajasthan 345001
								</div>
								<div className="list">
									<i className="fa fa-envelope-o"></i>
									<a
										href="mailto:contact@jaisalmertrust.org"
										target="_blank"
										rel="noopener noreferrer"
									>
										contact@jaisalmertrust.org
									</a>
								</div>
								<div className="list">
									<i className="fa fa-phone"></i>
									+91-96XXXXXX82
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-side">
							<form className="formcontact" onSubmit={sendEmail}>
								<label>Name</label>
								<input type="text" name="user_name" required />
								<label>Email</label>
								<input
									type="email"
									name="user_email"
									required
								/>
								<label>Message</label>
								<textarea name="message" required />
								<div id="success" className="hide">
									Your message has been sent...
								</div>
								<div id="failed" className="hide">
									Message failed...
								</div>
								<button type="submit" id="buttonsent">
									<span className="shine"></span>
									<span>Send</span>
								</button>
							</form>
						</div>
					</div>
				</div>
			</section>
			<section className="container-fluid p-0">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7115.448548918384!2d70.90659002699606!3d26.912244399440368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3947bc2890702d0f%3A0xaf9eb87583097150!2sJaisalmer%20Trust!5e0!3m2!1sen!2sin!4v1601227157785!5m2!1sen!2sin"
					width="100%"
					height="450"
					frameBorder="0"
					title='map'
					// style="border:0;"
					allowFullScreen=""
					aria-hidden="false"
					tabIndex="0"
				></iframe>
			</section>
			<Footer />
		</div>
	);
};
