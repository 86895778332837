import React from 'react';
const RazorpayPayment = ({ donationAmount }) => {
	// Define handlePayment function before using it in razorpayOptions
	const handlePayment = (response) => {
		// Handle the payment response here
		console.log(response);
	};
	const donation = { donationAmount } * 100;

	console.log(donation)
	const razorpayOptions = {
		key: process.env.REACT_APP_RAZORPAY_KEY, // Replace with your actual key ID
		secret: process.env.REACT_APP_RAZORPAY_SECRET_KEY,
		amount: donationAmount * 100, // Replace with the actual amount in paise
		currency: 'INR', // Replace with your currency code
		name: 'jaisalmer trust ',
		handler: handlePayment,
	};
	const handleClick = async () => {
		try {
			const Razorpay = new window.Razorpay(razorpayOptions);
			Razorpay.open();
			console.log(donation)

		} catch (error) {
			console.error('Error loading Razorpay:', error);
		}
	};

	return (
		<div>
			<div className="navbar-item RazorPay-Payment-Button pt-2 pb-2 px-4" onClick={handleClick}>Donate Now {donationAmount}&#x20B9;</div>
		</div>
	);
};
export default RazorpayPayment;
