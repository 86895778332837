import { Location, Redirect, Router } from '@reach/router';
import React from 'react';
import ScrollToTopBtn from './menu/ScrollToTop';
import Loader from './menu/loader';
// import About from './pages/about';
import Header from './menu/header';
import BadabaghProperty from './pages/badabaghProperty';
import Booking from './pages/booking';
import Contact from './pages/contact';
import DonationM from './pages/donation-m';
import Home from './pages/home';
import MuseumProperty from './pages/museumProperty';
import OrderFailed from './pages/order-failed';
import OrderSuccess from './pages/order-success';
import PrivacyPolicy from './pages/privacyPolicy';
import Property from './pages/property';
import RefundPolicy from './pages/refundPolicy';
import TermsAndConditions from './pages/termsAndConditions';
export const ScrollTop = ({ children, location }) => {
	React.useEffect(() => window.scrollTo(0, 0), [location]);
	return children;
};

const PosedRouter = ({ children }) => (
	<>

		<Location>
			{({ location }) => (
				<div id="routerhang">
					<div key={location.key}>
						<Loader />
						<Router location={location}>{children}</Router>
					</div>
				</div>
			)}
		</Location>
	</>
);

export default () => {


	return (
		<div className="wraper" >

			<Header />
			<PosedRouter>
				<ScrollTop path="/">
					<Home exact path="/">
						<Redirect to="/home" />
					</Home>
					{/* <About path="/about" /> */}
					<Property path="/golden-fort" id="62e0da3dc05cc7e79ebd88a1" />
					<Property path="/raja-ka-mahal" id="62e0da68a524fbc82400727f" />
					<Property path="/rani-ka-mahal" id="62e0da85a524fb9e47007280" />
					<Property path="/jawahar-niwas" id="5f57107959cfcacae5eda399" />
					{/* <Property path="/badabagh" id="5f57104a59cfca7dbceda397" /> */}
					<Property path="/moolsagar" id="5f57106259cfcab36deda398" />
					<Property path="/ghadisar-lake" id="5f57109f59cfcadde0eda39a" />
					<Property path="/annapurna" id="62e0dacba524fbe936007282" />
					<Property path="/paiga" id="62e0daaca524fb72c2007281" />
					<BadabaghProperty
						path="/badabagh"
						id="65bdeaebec8b12fd6c819e39"
					/>
					<MuseumProperty path="/museum" id="65bded58ec8b1255ea819e3a" />
					<Booking path="/book-now/:propertyId" />
					<Booking path="/book-now" />
					<Contact path="/contact" />
					<TermsAndConditions path="/terms-and-condition" />
					<PrivacyPolicy path="/privacyPolicy" />
					<RefundPolicy path="/refundPolicy" />
					<OrderSuccess path="/order-success" />
					<OrderFailed path="/order-failed" />

					<DonationM path="/donation-m" />

				</ScrollTop>
			</PosedRouter>
			<ScrollToTopBtn />
		</div>
	)
}
